// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-anleitungen-js": () => import("./../../../src/pages/anleitungen.js" /* webpackChunkName: "component---src-pages-anleitungen-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-stunden-js": () => import("./../../../src/pages/stunden.js" /* webpackChunkName: "component---src-pages-stunden-js" */),
  "component---src-templates-anleitung-js": () => import("./../../../src/templates/anleitung.js" /* webpackChunkName: "component---src-templates-anleitung-js" */),
  "component---src-templates-groupe-js": () => import("./../../../src/templates/groupe.js" /* webpackChunkName: "component---src-templates-groupe-js" */),
  "component---src-templates-homepage-js": () => import("./../../../src/templates/homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-member-js": () => import("./../../../src/templates/member.js" /* webpackChunkName: "component---src-templates-member-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-stunde-js": () => import("./../../../src/templates/stunde.js" /* webpackChunkName: "component---src-templates-stunde-js" */)
}

